body {
  width: calc(100% - 4rem);
  padding: 2rem;
  background-repeat: no-repeat;
  background-size: 100%;
  object-fit: cover;
  text-align: center;
  background-color: $background-color;
  background-position: 100% calc(100% + 4rem);
  background-image: url("https://uploads-ssl.webflow.com/63e7f7e770738021a2190b76/63e8b0882d6553290e7441aa_Waves.svg");
  @media screen and (max-width: 768px) {
    background-size: 200%;
    background-position: 50% calc(80% + 4rem);
  }
}

.App {
  text-align: center;
  height: calc(100vh - 4rem);
}

.center-vertically {
  position: relative;
  top: calc(50% - 12rem);
}
